import React from 'react';
import { Box, Flex, Heading, Text, Button } from '@chakra-ui/react';
import { FiArrowRight } from 'react-icons/fi';

const ServiceBox = () => {
const services = [
{
   
title: 'საზიარო ჰოსტინგი',
description: 'ჩვენ ვუქმნით ციფრულ სააგენტოებსა და & ელ-კომერციის ბიზნესებს მოქნილ პირობებს.',
price: '₾7',
redirectUrl: '/service1',
icon: <FiArrowRight />,
},
{
title: 'VPS ჰოსტინგი',
description: 'ჩვენ ვუქმნით ციფრულ სააგენტოებსა და & ელ-კომერციის ბიზნესებს მოქნილ პირობებს.',
price: '₾7',
redirectUrl: '/service2',
icon: <FiArrowRight />,
},
{
title: 'Managed VPS',
description: 'ჩვენ ვუქმნით ციფრულ სააგენტოებსა და & ელ-კომერციის ბიზნესებს მოქნილ პირობებს.',
price: '₾7',
redirectUrl: '/service3',
icon: <FiArrowRight />,
title: 'საზიარო ჰოსტინგი',
description: 'ჩვენ ვუქმნით ციფრულ სააგენტოებსა და & ელ-კომერციის ბიზნესებს მოქნილ პირობებს.',
price: '₾7',
redirectUrl: '/service1',
icon: <FiArrowRight />,
},






];

return (

<Flex flexWrap="wrap" justifyContent="center">
{services.map((service, index) => (
<Box
key={index}
maxW="sm"
borderWidth="1px"
borderRadius="lg"
overflow="hidden"
m={4}
boxShadow="lg"
transition="transform 0.3s"
_hover={{ transform: 'scale(1.05)' }}
bg="white"
p={6}
textAlign="center"
>
<Heading size="lg" mb={2}>
{service.title}
</Heading>
<Text fontSize="md" color="gray.500" mb={4}>
{service.description}
</Text>
<Text fontSize="lg" fontWeight="bold" mb={4}>
ფასი: {service.price}-დან
</Text>
<Button
colorScheme="messenger"
size="sm"
onClick={() => (window.location.href = service.redirectUrl)}
_hover={{ opacity: '0.8' }}
width="100%"
rightIcon={service.icon}
>
გეგმების ნახვა
</Button>
</Box>

))}
</Flex>

);
};

export default ServiceBox;