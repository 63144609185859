import { Box, Button, Text, VStack, HStack, SimpleGrid, useColorModeValue, Icon, useBreakpointValue, Container } from "@chakra-ui/react";
import { FaDatabase, FaEnvelope, FaTrafficLight, FaHdd } from "react-icons/fa";
import { useState } from "react";

const hostingPackages = [
  {
    name: "საბაზისო",
    space: "50GB",
    traffic: "100GB",
    emails: "10",
    databases: "2",
    monthlyPrice: 5,
    yearlyPrice: 50,
    color: "linear-gradient(45deg, #85d8ce, #3f87a6)"
  },
  {
    name: "Standard",
    space: "100GB",
    traffic: "200GB",
    emails: "20",
    databases: "4",
    monthlyPrice: 10,
    yearlyPrice: 100,
    color: "linear-gradient(45deg, #3f87a6, #ebf8e1)"
  },
  {
    name: "Premium",
    space: "200GB",
    traffic: "400GB",
    emails: "40",
    databases: "8",
    monthlyPrice: 20,
    yearlyPrice: 200,
    color: "linear-gradient(45deg, #ebf8e1, #f69d3c)"
  },
  {
    name: "Ultimate",
    space: "500GB",
    traffic: "1TB",
    emails: "100",
    databases: "20",
    monthlyPrice: 50,
    yearlyPrice: 500,
    color: "linear-gradient(45deg, #f69d3c, #d62828)"
  },
];

const HostingPackage = () => {
  const [isYearly, setIsYearly] = useState(false);
  const buttonBg = useColorModeValue("blue.600", "blue.200");
  const buttonColor = useColorModeValue("white", "gray.800");
  const columnBreakpoints = useBreakpointValue({ base: 1, sm: 2, md: 2, lg: 4 });
  const textColor = useColorModeValue("gray.700", "white");
  const yearlyButtonBg = useColorModeValue(isYearly ? "blue.600" : "gray.200", isYearly ? "blue.200" : "gray.700");
  const monthlyButtonBg = useColorModeValue(isYearly ? "gray.200" : "blue.600", isYearly ? "gray.700" : "blue.200");

  return (
    <Container maxWidth="1200px">
      <VStack spacing={4} width="100%">
        <HStack spacing={4}>
          <Button bg={monthlyButtonBg} color={buttonColor} onClick={() => setIsYearly(false)}>
            ყოველთვიურად
          </Button>
          <Button bg={yearlyButtonBg} color={buttonColor} onClick={() => setIsYearly(true)}>
            ყოველწლიურად
          </Button>
        </HStack>

        <SimpleGrid columns={columnBreakpoints} spacing={5} width="100%">
          {hostingPackages.map((pkg) => (
            <Box 
              borderWidth="1px" 
              borderRadius="lg" 
              p={4} 
              width="100%" 
              key={pkg.name} 
              transition="0.3s" 
              _hover={{ transform: "scale(1.05)" }}
              bgGradient={pkg.color}
              color={textColor}
            >
              <Text fontWeight="bold" fontSize="xl" textAlign="center">{pkg.name}</Text>
              <VStack mt={2} alignItems="start">
                <HStack>
                  <Icon as={FaHdd} boxSize={4} />
                  <Text>სივრცე: {pkg.space}</Text>
                </HStack>
                <HStack>
                  <Icon as={FaTrafficLight} boxSize={4} />
                  <Text>ტრაფიკი: {pkg.traffic}</Text>
                </HStack>
                <HStack>
                  <Icon as={FaEnvelope} boxSize={4} />
                  <Text>ელ-ფოსტები: {pkg.emails}</Text>
                </HStack>
                <HStack>
                  <Icon as={FaDatabase} boxSize={4} />
                  <Text>ბაზები: {pkg.databases}</Text>
                </HStack>
              </VStack>
              <VStack mt={2} alignItems="center">
                <Text fontSize="2xl" fontWeight="bold">
                  {isYearly ? `$${pkg.yearlyPrice}/წლიურად` : `$${pkg.monthlyPrice}/თვიურად`}
                </Text>
                <Button mt={2} bg={buttonBg} color={buttonColor} _hover={{ opacity: 0.8 }}>
                  შეკვეთა
                </Button>
              </VStack>
            </Box>
          ))}
        </SimpleGrid>
      </VStack>
    </Container>
  );
};

export default HostingPackage;
