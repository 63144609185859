import React, { useState } from "react";
import "./navbar.css";
import { Link } from "react-router-dom";
import { FaBars, FaUser } from "react-icons/fa";
import { ImCross } from "react-icons/im";

const Navbar = () => {
  const [isMobile, setIsMobile] = useState(false);
  const logoUrl = 'https://cleannet.ge/ln.PNG'; 
  const logoWidth = 145; 

  return (
    <>
      <nav className='navbar'>
        <img src={logoUrl} alt="CleanNet Logo" style={{ width: logoWidth }} />
        <ul className={isMobile ? "nav-links-mobile" : "nav-links"} onClick={() => setIsMobile(false)}>
          <Link to='/' className='home'>
            <li>მთავარი</li>
          </Link>
          <Link to='/services' className='services'>
            <li> სერვისები</li>
          </Link>
          <Link to='/services' className='services'>
            <li>დომენები</li>
          </Link>
          <Link to='/about' className='about'>
            <li>ჩვენს შესახებ</li>
          </Link>       
          <Link to='/contact' className='contact'>
            <li>კონტაქტი</li>
          </Link>
        
        
         
         
         
        </ul>
        <button className='mobile-menu-icon' onClick={() => setIsMobile(!isMobile)}>
          {isMobile ? <ImCross /> : <FaBars />}
        </button>
      </nav>
    </>
  );
}

export default Navbar;
