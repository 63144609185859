import { Box, Grid, Icon, Text, useColorModeValue } from "@chakra-ui/react";
import { FaCheckCircle } from "react-icons/fa";

const Feature = ({ title, description }) => (
  <Box
    p={6}
    borderWidth={1}
    borderRadius="lg"
    boxShadow="sm"
    bg={useColorModeValue('white', 'gray.800')}
    transition="all 0.25s"
    _hover={{ transform: "translateY(-5px)", boxShadow: "lg" }}
  >
    <Grid templateColumns="auto 1fr" gap={2} alignItems="center" mb={4}>
      <Icon as={FaCheckCircle} color="green.500" boxSize={6} 
        transition="color 0.3s"
        _hover={{ color: "green.300" }}
      />
      <Text 
        fontWeight="bold"
        transition="color 0.3s"
        _hover={{ color: "teal.500" }}
      >
        {title}
      </Text>
    </Grid>
    <Text fontSize="sm" lineHeight="tall">{description}</Text>
  </Box>
);

const FeaturesComponent = () => (
  
  <Grid
    templateColumns={{ base: "repeat(1, 1fr)", md: "repeat(2, 1fr)", lg: "repeat(3, 1fr)" }}
    gap={8}
    maxW="1200px"
    mx="auto"
    mt={8}
    px={4}
  >
    
    <Feature
      title="ოპტიმიზირებული სერვერების სისწრაფისთვის"
      description="ჩვენი სერვერები ოპტიმიზირებულია მაღალი სისწრაფისთვის, რათა თქვენი ვებგვერდების ჩატვირთვის დრო მინიმუმამდე შემცირდეს.      "
    />
    <Feature
      title="მხარდაჭერა, რომელსაც შეგიძლიათ ენდოთ      "
      description="გახდი ჩვენი ნაწილი. გაქვთ შეკითხვა? ჩვენი მხარდაჭერის გუნდი ყოველთვის მზადაა დასახმარებლად.
      "
    />
    <Feature
      title="მაღალი უსაფრთხოება "
      description="ჩვენ სერიოზულად ვუყურებთ უსაფრთხოებას. ჩვენს სერვერებს აქვთ უსაფრთხოების გაუმჯობესებული ფუნქციები და ფაირვოლები.      "
    />
    <Feature
      title="ადვილად გამოსაყენებელი ხელსაწყოები და აპლიკაციები რომელსაც აკონტროლებთ"
      description="შექმენით ვებგვერდი და ელფოსტები რამოდენიმე წუთში.      "
    />
    <Feature
      title="WordPress -თან მეგობრული ჰოსტინგი      "
      description="დაიწყეთ დღესვე წინასწარ დაინსტალირებული WordPress-ით, რომელიც განთავსებულია WordPress ვებსაიტებისთვის სრულყოფილად მორგებულ სერვერებზე."
    />
    <Feature
      title="უსაფრთხო და საიმედო ბიზნეს ელფოსტები      "
      description="უსაფრთხოდ მართეთ თქვენი ბიზნესი ჩვენი უფასო საწარმოს დონის ელფოსტის საშუალებით.      "
    />
  </Grid>
);

export default FeaturesComponent;
