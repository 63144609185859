import React from "react"

const Skills = () => {
  return (
    <>
      <section className='hero'>
        <h1>Welcome To Skills Page</h1>
      </section>
    </>
  )
}
export default Skills
