import React from "react";
import { ChakraProvider, CSSReset, Box } from '@chakra-ui/react';
import SlideBox from './SlideBox';
import ServiceBox from "./ServiceBox";
import Features from "./Features";
import DomainSearch from "../DomainSearch";
import Footer from "../Footer";
import SectionBanner from "../SectionBanner";
import Heading from "./Heading"
import SeeAll from "./SeeAll"
import FeaturesSection from "./FeaturesSection"
import Three from "./Three"
import ReviewsSection from "./ReviewsSection"
import WebHosting from "./WebHosting"
import AllYouNeed from "./AllYouNeed"
import CtaOne from "./CtaOne";
import Services from "./Services";
import PageHeader from "./common/PageHeader";





const Home = () => {
  const slides = [
    { 
      title: "საზიარო ჰოსტინგი ", 
      description: "ჩვენ ვუქმნით ციფრულ სააგენტოებსა და & ელ-კომერციის ბიზნესებს მოქნილ პირობებს..", 
      image: "https://img.freepik.com/free-photo/cloud-storage-background-remixed-from-public-domain-by-nasa_53876-104240.jpg", 
      topImage: "https://cleannet.ge/ln.PNG", 
      link: "https://example.com/page1" 
    },
    { 
      title: "We provide awesome services", 
      description: "Read about our services.", 
      image: "https://via.placeholder.com/1920x1080", 
      topImage: "https://via.placeholder.com/150", 
      link: "https://example.com/page2" 
    },
    { 
      title: "Contact us for more info", 
      description: "We'd love to hear from you.", 
      image: "https://via.placeholder.com/1920x1080", 
      topImage: "https://via.placeholder.com/150", 
      link: "https://example.com/contact" 
    },
  ];

  return (
    
    <ChakraProvider>
      <SectionBanner />
      <Three />
      <Features />

      <Services />

      <SeeAll />
      <ServiceBox />
      <Box p={4}>
        <DomainSearch />
      </Box>
      <Features />
      <FeaturesSection />
      <Three />
      <ReviewsSection />
    </ChakraProvider>
  )
}

export default Home;
