import React from 'react';
import {
  Box,
  Heading,
  FormControl,
  FormLabel,
  Input,
  Button,
  ChakraProvider,
  extendTheme,
} from '@chakra-ui/react';

const theme = extendTheme({
  fonts: {
    heading: 'Poppins, sans-serif',
    body: 'Poppins, sans-serif',
  },
});

const LoginPage = () => {
  return (
    <ChakraProvider theme={theme}>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        height="60vh"
      >
        <Box
          width="400px"
          p={8}
          bg="white"
          borderWidth={1}
          borderRadius="lg"
          boxShadow="lg"
        >
          <Heading mb={15} textAlign="center" color="messenger">
            ავტორიზაცია
          </Heading>
          <FormControl id="email" mb={4}>
            <FormLabel>ელ-ფოსტა</FormLabel>
            <Input type="email" />
          </FormControl>
          <FormControl id="password" mb={6}>
            <FormLabel>პაროლი</FormLabel>
            <Input type="password" />
          </FormControl>
          <Button colorScheme="messenger" size="lg" width="100%">
            შესვლა
          </Button>
        </Box>
      </Box>
      
    </ChakraProvider>
  );
};

export default LoginPage;
