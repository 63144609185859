import React from "react"
import Navbar from "./compoent/Navbar"
import Home from "./compoent/Home"
import About from "./compoent/About"
import Skills from "./compoent/Skills"
import Contact from "./compoent/Contact"
import Services from "./compoent/Services"
import Login from "./compoent/Login"
import Footer from "./Footer"
import HeLine from "./compoent/HeLine"
import './index.css';

import { ChakraProvider, CSSReset, Box } from '@chakra-ui/react';
import { BrowserRouter as Router, Switch, Route } from "react-router-dom"
import "swiper/css";
import 'swiper/css/navigation';

//bootstrap
//npm install react-router-dom@5

function App() {
  return (
    <>
     <ChakraProvider>
    <HeLine />
    </ChakraProvider>
      <Router>
        <Navbar />

        <Switch>
          <Route path='/' compoent={Home} exact>
            <Home />
          </Route>
          <Route path='/about' compoent={About} exact>
            <About />
          </Route>
          <Route path='/skills' compoent={Skills} exact>
            <Skills />
          </Route>
          <Route path='/contact' compoent={Contact} exact>
            <Contact />
          </Route>
          <Route path='/services' compoent={Services} exact>
            <Services />
          </Route>
          <Route path='/login' compoent={Services} exact>
            <Login />
          </Route>
         
        </Switch>
      </Router>
      <ChakraProvider>
      <Footer />
      </ChakraProvider>
    </>
  )
}

export default App
