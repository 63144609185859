import React from 'react';
import { Link } from 'react-router-dom';
import { FaHome } from 'react-icons/fa';
import { faChartLine, faFileChartLine, faUserFriends, faSpellCheck, faCog, faCheckCircle, faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';



const FeatureTwo = ({ cardDark }) => {
  return (
    <>
      <section
        className={`feature-section ptb-120 ${
          cardDark ? 'bg-dark' : 'bg-light'
        }`}
      >
        <div className='container'>
          <div className='row justify-content-center'>
            <div className='col-lg-6 col-md-10'>
        
            </div>
          </div>
          <div className='row'>
            <div className='col-12'>
              <div className='feature-grid'>
                <div
                  className={`shadow-sm highlight-card rounded-custom p-5 ${
                    cardDark
                      ? 'bg-custom-light promo-border-hover border border-2 border-light text-white'
                      : 'bg-white'
                  }`}
                  data-aos='fade-up'
                  data-aos-delay='50'
                >
                  <div
                    className='
                      icon-box
                      d-inline-block
                      rounded-circle
                      bg-primary-soft
                      mb-32
                    '
                  >
                    <i className='fa-solid fa-server'></i>
                  </div>
                  <div className='feature-content'>
                    <h3 className='h5'>Managed VPS ჰოსტინგი</h3>
                    <p>
                    მართეთ თქვენი სერვერი მცირე ტექნიკური ცოდნით, ნებისმიერ საკითხს, ვმართავთ ჩვენ
                    </p>
                    <p>
                    ჩვენ ვუქმნით ციფრულ სააგენტოებსა და & ელ-კომერციის ბიზნესებს მოქნილ პირობებს.
                    </p>
                    <h6 className='mt-4'>რატომ?</h6>
                    <ul className='list-unstyled mb-0'>
                      <li className='py-1'>
                        <i
                          className={`fa-solid fa-check me-2 ${
                            cardDark ? 'text-warning' : 'text-primary'
                          }`}
                        ></i>
                        სატესტო მახასიათებელი
                      </li>
                      <li className='py-1'>
                        <i
                          className={`fa-solid fa-check me-2  ${
                            cardDark ? 'text-warning' : 'text-primary'
                          }`}
                        ></i>
                        სატესტო მახასიათებელი
                      </li>
                      <li className='py-1'>
                        <i
                          className={`fa-solid fa-check me-2 ${
                            cardDark ? 'text-warning' : 'text-primary'
                          }`}
                        ></i>
                        სატესტო მახასიათებელი
                      </li>
                      <li className='py-1'>
                        <i
                          className={`fa-solid fa-check me-2 ${
                            cardDark ? 'text-warning' : 'text-primary'
                          }`}
                        ></i>
                        სატესტო მახასიათებელი
                      </li>
                      <li className='py-1'>
                        <i
                          className={`fa-solid fa-check me-2 ${
                            cardDark ? 'text-warning' : 'text-primary'
                          }`}
                        ></i>
                        სატესტო მახასიათებელი
                      </li>
                      <li className='py-1'>
                        <i
                          className={`fa-solid fa-check me-2 ${
                            cardDark ? 'text-warning' : 'text-primary'
                          }`}
                        ></i>
                        სატესტო მახასიათებელი
                      </li>
                    </ul>
                  </div>
                  <Link
                    to='/single-service'
                    className='link-with-icon text-decoration-none mt-3'
                  >
                    სრულად ნახვა<i className='fa-solid fa-circle'></i>
                  </Link>
                </div>
                <div
                  className={`feature-card shadow-sm rounded-custom p-5 ${
                    cardDark
                      ? 'bg-custom-light promo-border-hover border border-2 border-light text-white'
                      : 'bg-white'
                  }`}
                  data-aos='fade-up'
                  data-aos-delay='50'
                >
                  <div
                    className='
                      icon-box
                      d-inline-block
                      rounded-circle
                      bg-success-soft
                      mb-32
                    '
                  >
                    <i className='fa-solid fa-cloud'></i>
                  </div>
                  <div className='feature-content'>
                    <h3 className='h5'>საზიარო ჰოსტინგი</h3>
                    <p className='mb-0'>
                    ჩვენ ვუქმნით ციფრულ სააგენტოებსა და & ელ-კომერციის ბიზნესებს მოქნილ პირობებს.
                    </p>
                  </div>
                  <Link
                    to='/single-service'
                    className='link-with-icon text-decoration-none mt-3'
                  >
                    სრულად ნახვა <i className=''></i>
                  </Link>
                </div>
                <div
                  className={`feature-card shadow-sm rounded-custom p-5 ${
                    cardDark
                      ? 'bg-custom-light promo-border-hover border border-2 border-light text-white'
                      : 'bg-white'
                  }`}
                  data-aos='fade-up'
                  data-aos-delay='50'
                >
                  <div
                    className='
                      icon-box
                      d-inline-block
                      rounded-circle
                      bg-danger-soft
                      mb-32
                    '
                  >
                    <i className='fa-solid fa-cloud'></i>
                  </div>
                  <div className='feature-content'>
                    <h3 className='h5'>VPS ჰოსტინგი</h3>
                    <p className='mb-0'>
                    ჩვენ ვუქმნით ციფრულ სააგენტოებსა და & ელ-კომერციის ბიზნესებს მოქნილ პირობებს.
                    </p>
                  </div>
                  <Link
                    to='/single-service'
                    className='link-with-icon text-decoration-none mt-3'
                  >
                    სრულად ნახვა  <i className=''></i>
                  </Link>
                </div>
                <div
                  className={`feature-card shadow-sm rounded-custom p-5 ${
                    cardDark
                      ? 'bg-custom-light promo-border-hover border border-2 border-light text-white'
                      : 'bg-white'
                  }`}
                  data-aos='fade-up'
                  data-aos-delay='50'
                >
                  <div
                    className='
                      icon-box
                      d-inline-block
                      rounded-circle
                      bg-dark-soft
                      mb-32
                    '
                  >
                    <i className='fa-solid fa-cloud'></i>
                  </div>
                  <div className='feature-content'>
                    <h3 className='h5'>Managed WordPress ჰოსტინგი</h3>
                    <p className='mb-0'>
                    ჩვენ ვუქმნით ციფრულ სააგენტოებსა და & ელ-კომერციის ბიზნესებს მოქნილ პირობებს.
                    </p>
                  </div>
                  <Link
                    to='/single-service'
                    className='link-with-icon text-decoration-none mt-3'
                  >
                   სრულად ნახვა  <i className=''></i>
                  </Link>
                </div>
                <div
                  className={`feature-card shadow-sm rounded-custom p-5 ${
                    cardDark
                      ? 'bg-custom-light promo-border-hover border border-2 border-light text-white'
                      : 'bg-white'
                  }`}
                  data-aos='fade-up'
                  data-aos-delay='50'
                >
                  <div
                    className='
                      icon-box
                      d-inline-block
                      rounded-circle
                      bg-warning-soft
                      mb-32
                    '
                  >
                    <i className='fa-solid fa-cloud'></i>
                  </div>
                  <div className='feature-content'>
                    <h3 className='h5'>დომენის რეგისტრაცია</h3>
                    <p className='mb-0'>
                    ჩვენ ვუქმნით ციფრულ სააგენტოებსა და & ელ-კომერციის ბიზნესებს მოქნილ პირობებს.
                    </p>
                  </div>
                  <Link
                    to='/single-service'
                    className='link-with-icon text-decoration-none mt-3'
                  >
                    სრულად ნახვა <i className=''></i>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default FeatureTwo;
