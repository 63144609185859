import React from "react";
import { Box, Container, Heading, Text, Button, Icon, useBreakpointValue } from "@chakra-ui/react";
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { ChevronLeftIcon, ChevronRightIcon } from "@chakra-ui/icons";

const SectionBanner = () => {
  const slides = [
    {
      bgImage: "https://www.cleannet.ge/data/statistic/bc449be696.svg",
      heading: "🔥 Cloud გადაწყვეტილებები",
      text: "ჩვენ ვმართავთ და ვახდენთ მონიტორინგს რეალურ დროში, რათა ყველთვის დავიცვათ თქვენი ვებგვერდების უწყვეტი კავშირი."
    },
    {
      bgImage: "https://www.hostinger.com/tutorials/wp-content/uploads/sites/2/2022/01/how-to-schedule-posts-in-wordpress.webp",
      heading: "WordPress ჰოსტინგი",
      text: "მიიღეთ უახლესი გამოცდილება ჩვენი ვორდპრეს ჰოსტინგით, ჩვენ ვმართავთ და ვახდენთ მონიტორინგს რეალურ დროში, რათა ყველთვის დავიცვათ.",
    },
    {
      bgImage: "https://www.hostinger.com/blog/wp-content/uploads/sites/4/2022/10/image-1.webp",
      heading: "დაარეგისტრირეთ დომენი",
      text: "მიიღეთ უახლესი გამოცდილება ჩვენი ვორდპრეს ჰოსტინგით, ჩვენ ვმართავთ და ვახდენთ მონიტორინგს რეალურ დროში, რათა ყველთვის დავიცვათ",
    },
    // Add more slides here...
  ];

  const sliderHeight = useBreakpointValue({ base: "400px", md: "400px", lg: "300px" });

  return (
    <Container maxW="container.xl" p={0}>
      <Box marginTop={{ base: "6", md: "8" }}>
        <Carousel 
          showThumbs={false}
          showIndicators={false}
          showStatus={false}
          infiniteLoop={true}
          autoPlay={true}
          interval={50000}
          dynamicHeight={true}
          renderArrowPrev={(onClickHandler, hasPrev, label) => 
            hasPrev && (
              <Box as="button" onClick={onClickHandler} title={label} position="absolute" left="15px" top="50%" zIndex="2">
                <Icon as={ChevronLeftIcon} boxSize={10} />
              </Box>
            )
          }
          renderArrowNext={(onClickHandler, hasNext, label) => 
            hasNext && (
              <Box as="button" onClick={onClickHandler} title={label} position="absolute" right="15px" top="50%" zIndex="2">
                <Icon as={ChevronRightIcon} boxSize={10} />
              </Box>
            )
          }
        >
          {slides.map((slide, index) => (
            <Box
              key={index}
              bgImage={`url(${slide.bgImage})`}
              bgRepeat="no-repeat"
              bgSize="cover"
              bgPosition="center"
              borderRadius="25px"
              p={{ base: "6", md: "8" }}
              color="white"
              height={sliderHeight}
              width="100%"
              mx="auto"
            >
              <Box maxW={{ base: "90%", md: "70%", lg: "80%" }} mx="auto" textAlign="center">
                <Heading mb={5}>
                  {slide.heading}
                </Heading>
                <Text fontSize={{ base: "lg", md: "xl" }} mb={6}>
                  {slide.text}
                </Text>
                <Button colorScheme="whiteAlpha" size="lg" width="full">დაწვრილებით</Button>
              </Box>
            </Box>
          ))}
        </Carousel>
      </Box>
    </Container>
  );
};

export default SectionBanner;
