import React from 'react';
import { Box, Button, Text, VStack, useColorModeValue } from '@chakra-ui/react';
import { Link } from 'react-router-dom';

const SectionHeaders = () => {
  const start = useColorModeValue("green.400", "green.800");
  const end = useColorModeValue("blue", "blue.500");

  return (
    <VStack spacing={5} textAlign="center" py={5}>
      <Box 
        w={1200} 
        p={2} 
        boxShadow="xl" 
        borderWidth={1} 
        rounded="md" 
        bgGradient={`linear(to-l, ${start}, ${end})`}
        display="flex"
        justifyContent="space-between"
        alignItems="left"
      >
        <Text fontSize="xl" fontWeight="bold" color="white">
          მოთხოვნადი სერვისები
        </Text>
      
      </Box>
    </VStack>
  );
};

export default SectionHeaders;
