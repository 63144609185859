import React from 'react';
import ContactBox from './ContactBox';
import SectionTitle from './SectionTitle';

const ContactFormTwo = () => {
  return (
    <>
    <SectionTitle />
      <section
        className='contact-us-form pt-60 pb-120'
        style={{
          background:
            "url('assets/img/shape/contact-us-bg.svg')no-repeat center bottom",
        }}
      >
        <div className='container'>
          <div className='row justify-content-lg-between align-items-center'>
            <div className='col-lg-6 col-md-8'>
              <div className='section-heading'>
                <h2>დაუკავშირდით ჩვენს გაყიდვების დეპარტამენტს</h2>
                <p>
                სტანდარტიზებული ინფრასტრუქტურის მეშვეობით ხელი შეუწყეთ კლიენტზე ორიენტირებული კონვერგენციას მომხმარებელზე მიმართული გასწორებების მიმართ.
                </p>
              </div>
              <form action='#' className='register-form'>
                <div className='row'>
                  <div className='col-sm-6'>
                    <label htmlFor='firstName' className='mb-1'>
                      სახელი<span className='text-danger'>*</span>
                    </label>
                    <div className='input-group mb-3'>
                      <input
                        type='text'
                        className='form-control'
                        id='firstName'
                        required
                        placeholder='სახელი'
                        aria-label='სახელი'
                      />
                    </div>
                  </div>
                  <div className='col-sm-6 '>
                    <label htmlFor='lastName' className='mb-1'>
                      გვარი
                    </label>
                    <div className='input-group mb-3'>
                      <input
                        type='text'
                        className='form-control'
                        id='lastName'
                        placeholder='გვარი'
                        aria-label='გვარი'
                      />
                    </div>
                  </div>
                  <div className='col-sm-6'>
                    <label htmlFor='phone' className='mb-1'>
                      ტელეფონი <span className='text-danger'>*</span>
                    </label>
                    <div className='input-group mb-3'>
                      <input
                        type='text'
                        className='form-control'
                        id='phone'
                        required
                        placeholder='ტელეფონი'
                        aria-label='Phone'
                      />
                    </div>
                  </div>
                  <div className='col-sm-6'>
                    <label htmlFor='email' className='mb-1'>
                      ელ-ფოსტა<span className='text-danger'>*</span>
                    </label>
                    <div className='input-group mb-3'>
                      <input
                        type='email'
                        className='form-control'
                        id='email'
                        required
                        placeholder='ელ-ფოსტა'
                        aria-label='Email'
                      />
                    </div>
                  </div>
                  <div className='col-12'>
                    <label htmlFor='yourMessage' className='mb-1'>
                      შეტყობინება <span className='text-danger'>*</span>
                    </label>
                    <div className='input-group mb-3'>
                      <textarea
                        className='form-control'
                        id='yourMessage'
                        required
                        placeholder='რით შეგვიძლია დაგეხმაროთ'
                        style={{ height: '120px' }}
                      ></textarea>
                    </div>
                  </div>
                </div>
                <button type='submit' className='btn btn-primary mt-4'>
                  გაგზავნა
                </button>
              </form>
            </div>
            <div className='col-lg-5 col-md-10'>
              <div className='contact-us-img'>
                <img
                  src='assets/img/contact-us-img-2.svg'
                  alt='contact us'
                  className='img-fluid'
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      <ContactBox />
    </>
  );
};

export default ContactFormTwo;
