import React from "react";
import { Box, Link, Stack, Text, Flex, Spacer, useColorModeValue } from "@chakra-ui/react";

const Footer = () => {
  const hoverColor = useColorModeValue("blue.600", "blue.300");
  const bgColor = useColorModeValue("gray.100", "gray.900");
  const textColor = useColorModeValue("gray.600", "gray.200");

  return (
    <Box as="footer" role="contentinfo" py="6" px={{ base: '4', md: '8' }} bg={bgColor} color={textColor} mt={10}>
      <Flex direction={{ base: 'column', md: 'row' }} maxW={{ base: 'xl', md: '7xl' }} mx="auto">
        <Stack spacing="4">
          <Text fontWeight="bold" mb="2">კომპანია</Text>
          <Link href="#" _hover={{ color: hoverColor }}>ჩვენს შესახებ</Link>
          <Link href="#" _hover={{ color: hoverColor }}>კონფ. პოლიტიკა</Link>
          <Link href="#" _hover={{ color: hoverColor }}>კარიერა</Link>
          <Link href="#" _hover={{ color: hoverColor }}>რეფერალური პროგრამა</Link>
        </Stack>
        <Spacer />
        <Stack spacing="4">
          <Text fontWeight="bold" mb="2">სერვისები</Text>
          <Link href="#" _hover={{ color: hoverColor }}>ვებ ჰოსტინგი</Link>
          <Link href="#" _hover={{ color: hoverColor }}>WordPress ჰოსტინგი</Link>
          <Link href="#" _hover={{ color: hoverColor }}>VPS ჰოსტინგი</Link>
          <Link href="#" _hover={{ color: hoverColor }}>Windows ჰოსტინგი</Link>
          <Link href="#" _hover={{ color: hoverColor }}>Managed VPS</Link>
          <Link href="#" _hover={{ color: hoverColor }}>SSL სერთიფიკატები</Link>
          <Link href="#" _hover={{ color: hoverColor }}>დომენის რეგისტრაცია</Link>
          <Link href="#" _hover={{ color: hoverColor }}>გამოყოფილი სერვერები</Link>
          <Link href="#" _hover={{ color: hoverColor }}>დომენის ტრანსფერი</Link>
        </Stack>
        <Spacer />
        <Stack spacing="4">
          <Text fontWeight="bold" mb="2">დახმარება</Text>
          <Link href="#" _hover={{ color: hoverColor }}>ბილეთის გახსნა</Link>
          <Link href="#" _hover={{ color: hoverColor }}>გადახდის მეთოდები</Link>
          <Link href="#" _hover={{ color: hoverColor }}>ცოდნის ბაზა</Link>
        </Stack>
        <Spacer />
        <Stack spacing="4">
          <Text fontWeight="bold" mb="2">კონტაქტი</Text>
          <Link href="#" _hover={{ color: hoverColor }}>დოკუმენტაცია</Link>
          <Link href="#" _hover={{ color: hoverColor }}>GE დომენის რეგისტრაციისა და ადმინისტრაციის წესები</Link>
          <Link href="#" _hover={{ color: hoverColor }}>დომენის რეგისტრაციის ხელშეკრულება</Link>
          <Link href="#" _hover={{ color: hoverColor }}>ჰოსტინგის ხელშეკრულება</Link>
          <Link href="#" _hover={{ color: hoverColor }}>დომენის პერსონალურ მონაცემთა დაცვის პოლიტიკა</Link>
        </Stack>
      </Flex>
      <Box textAlign="center" pt="6">
        <Text>© 2017-2023 CleanNet ყველა უფლება დაცულია.</Text>
      </Box>
    </Box>
  );
};

export default Footer;
