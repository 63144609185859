import { Box, Text, Image, VStack, Heading, IconButton, Button, Link } from "@chakra-ui/react";
import React, { useState } from "react";
import { ChevronLeftIcon, ChevronRightIcon } from "@chakra-ui/icons";

const SlideBox = ({ slides }) => {
  const [activeIndex, setActiveIndex] = useState(0);

  const handleNext = () => {
    setActiveIndex((prevIndex) => (prevIndex + 1) % slides.length);
  };

  const handlePrev = () => {
    setActiveIndex((prevIndex) => (prevIndex - 1 + slides.length) % slides.length);
  };

  return (
    <Box display="flex" alignItems="center" justifyContent="center" flexDirection="column" minHeight="40vh" minWidth="40vw" position="relative">
      <Box
        bgImage={`url(${slides[activeIndex].image})`}
        bgSize="cover"
        bgPosition="center"
        w="full"
        h="full"
        position="absolute"
      />
      <Box
        w="full"
        h="full"
        position="absolute"
        backgroundColor="rgba(0,0,0,0.5)"
      />
      <Image
        src={slides[activeIndex].topImage}
        alt={slides[activeIndex].title}
        w={32}
        h={12}
        objectFit="cover"
        mb={8}
      />
      <VStack align="center" position="relative" p={1} maxWidth="" color="white" spacing={9}>
        <Heading size="2xl">{slides[activeIndex].title}</Heading>
        <Text fontSize="xl">{slides[activeIndex].description}</Text>
        <Link href={slides[activeIndex].link} isExternal>
          <Button colorScheme="brand" size="lg">გეგმების ნახვა</Button>
        </Link>
      </VStack>
      <IconButton
        icon={<ChevronLeftIcon w={8} h={8} />}
        position="absolute"
        left={8}
        top="50%"
        colorScheme="whiteAlpha"
        variant="ghost"
        size="lg"
        onClick={handlePrev}
      />
      <IconButton
        icon={<ChevronRightIcon w={8} h={8} />}
        position="absolute"
        right={8}
        top="50%"
        colorScheme="whiteAlpha"
        variant="ghost"
        size="lg"
        onClick={handleNext}
      />
    </Box>
  );
};

export default SlideBox;
