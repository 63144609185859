import React, { useState } from 'react';
import {
  Flex,
  Box,
  Text,
  HStack,
  VStack,
  Icon,
  Input,
  InputGroup,
  InputRightElement,
  Button,
  Spinner,
  Center,
  Grid,
  GridItem,
} from '@chakra-ui/react';
import { MdDomain, MdLanguage, MdSettings } from 'react-icons/md';
import { motion } from 'framer-motion';
import { SearchIcon } from '@chakra-ui/icons';
import { ChevronDownIcon } from '@chakra-ui/icons';

const MotionBox = motion(Box);

const DomainSearch = ({ domain, setDomain, searchDomain, loading, result, renderDomainResult, suggestions }) => (
  <VStack spacing={6} w="full">
    <InputGroup size="lg" w="full">
      <Input
        type="text"
        placeholder="🔥 მოძებნეთ თქვენი დომენი..."
        value={domain}
        onChange={(e) => setDomain(e.target.value)}
        bg="white"
        borderRadius="full"
        boxShadow="lg"
        p={6}
        fontSize="lg"
        color="black"
      />
      <InputRightElement>
        <Button
          icon={<SearchIcon />}
          colorScheme="blue"
          borderRadius="full"
          onClick={searchDomain}
          p={6}
        >
          <SearchIcon />
        </Button>
      </InputRightElement>
    </InputGroup>
    {loading && <Spinner size="xl" color="teal.500" />}
    {!loading && result && (
      <Box id="resultContainer" w="full" p={4} borderRadius="md" boxShadow="md" bg="white" color="black">
        {renderDomainResult(result.data, result.domain)}
        <VStack spacing={4}>
          {suggestions.map((suggestion) => renderDomainResult(suggestion.data, suggestion.domain))}
        </VStack>
      </Box>
    )}
    <HStack spacing={6} pt={6} wrap="wrap" justify="center">
      {[
        { ext: '.GE', price: '30 ₾' },
        { ext: '.COM', price: '30 ₾' },
        { ext: '.NET', price: '30 ₾' },
        { ext: '.ORG', price: '30 ₾' },
        { ext: '.com', price: '30 ₾' },
      ].map((domain) => (
        <Box key={domain.ext} bg="blue.100" p={4} borderRadius="md" boxShadow="md" textAlign="center">
          <Text fontSize="2xl" fontWeight="bold" color="blue.700">
            {domain.ext}
          </Text>
          <Text fontSize="md" color="gray.600">
            {domain.price}
          </Text>
        </Box>
      ))}
    </HStack>
  </VStack>
);

const HostingPlans = () => (
  <VStack spacing={6} w="full">
    <HStack spacing={4} pt={6} wrap="wrap" justify="center">
      <Button as="a" href="#" colorScheme="blue">
        საზიარო
      </Button>
      <Button as="a" href="#" colorScheme="blue">
        Managed VPS 
      </Button>
      <Button as="a" href="#" colorScheme="blue">
        WordPress ჰოსტინგი
      </Button>
    
    </HStack>
  </VStack>
);

const WebsiteCreationInfo = () => (
  <Box p={4} maxW="2xl" textAlign="center" bg="blue.50" borderRadius="md" boxShadow="md">
    <Text fontSize="lg">
      იმისთვის რომ შექმნათ თქვენი არაჩვეულებრივი ვებგვერდი, თქვენ უნდა გქონდეთ წინა ორი ეტაპი გავლილი, გქონდეთ აქტიური დომენი და ჰოსტინგი. 🚀😊
    </Text>
  </Box>
);

const BannerSection = () => {
  const [selected, setSelected] = useState('აირჩიეთ დომენის სახელი');
  const [domain, setDomain] = useState('');
  const [loading, setLoading] = useState(false);
  const [result, setResult] = useState(null);
  const [suggestions, setSuggestions] = useState([]);

  const checkDomain = async (domain) => {
    const response = await fetch(`https://cleannet.ge/checkdomain.php?domain=${domain}`);
    const data = await response.json();
    return data;
  };

  const searchDomain = async () => {
    setLoading(true);
    setResult(null);
    setSuggestions([]);
    const mainDomain = domain.includes('.') ? domain : `${domain}.ge`;
    const mainDomainData = await checkDomain(mainDomain);
    setResult({ domain: mainDomain, data: mainDomainData });

    const suggestedDomains = ['com.ge', 'org.ge', 'net.ge', 'edu.ge'];
    const suggestionResults = await Promise.all(
      suggestedDomains.map((ext) => checkDomain(`${domain.split('.')[0]}.${ext}`))
    );
    setSuggestions(
      suggestedDomains.map((ext, index) => ({
        domain: `${domain.split('.')[0]}.${ext}`,
        data: suggestionResults[index],
      }))
    );
    setLoading(false);
  };

  const getDomainPrice = (domain) => {
    const domainParts = domain.split('.');
    const zone = domainParts[domainParts.length - 1];
    if (zone === 'ge' && domainParts[0].length === 2 && !['com', 'net'].includes(domainParts[1])) {
      return 900;
    }
    const prices = {
      'com.ge': 12,
      'org.ge': 12,
      'net.ge': 12,
      'edu.ge': 12,
      com: 50,
      ge: 30,
      org: 52,
      net: 50,
      info: 40,
    };
    return prices[`${domainParts[1]}.${zone}`] || 30;
  };

  const renderDomainResult = (domainData, domain) => {
    if (!domainData) return null;
    const { result, status } = domainData;
    const price = getDomainPrice(domain);
    if (result === 'success' && status === 'available') {
      return (
        <Box className="result available" my={4} p={4} boxShadow="md" borderRadius="md" bg="green.100">
          <Text>{domain} დომენი თავისუფალია რეგისტრაციისთვის 🎉</Text>
          <a href={`https://cleannet.ge/cart.php?a=add&domain=register&query=${domain}`}>
            <Button colorScheme="teal" mt={2}>შეძენა ₾{price}</Button>
          </a>
        </Box>
      );
    }
    return (
      <Box className="result unavailable" my={4} p={4} boxShadow="md" borderRadius="md" bg="red.100">
        <Text>{domain} სამწუხაროდ დომენი უკვე დაკავებულია 😞</Text>
        {domain.includes('.ge') && (
          <a href={`https://cleannet.ge/cart.php?a=add&domain=transfer&query=${domain}`}>
            <Button colorScheme="orange" mt={2}>დაატრანსფერე ჩვენთან 😎</Button>
          </a>
        )}
      </Box>
    );
  };

  const iconVariants = {
    hidden: { opacity: 0, scale: 0 },
    visible: { opacity: 1, scale: 1 },
  };

  return (
    <Flex direction="column" alignItems="center" py={8}>
      <HStack spacing={8} justify="center" wrap="wrap">
        <MotionBox
          as={VStack}
          initial="hidden"
          animate="visible"
          variants={iconVariants}
          transition={{ duration: 0.5 }}
          borderWidth="2px"
          borderColor="blue.500"
          borderRadius="lg"
          p={8}
          width={["auto", "auto", "auto", 80]}
          height="auto"
          textAlign="center"
          cursor="pointer"
          bg={selected === 'აირჩიეთ დომენის სახელი' ? 'blue.100' : 'white'}
          onClick={() => setSelected('აირჩიეთ დომენის სახელი')}
          position="relative"
        >
          <Box as="span" display="inline-block" p={4} borderRadius="full" bg="blue.200">
            <Icon as={MdDomain} boxSize={12} color="blue.500" />
          </Box>
          <Text mt={4} fontWeight="bold" color="blue.700">
            აირჩიეთ დომენის სახელი
          </Text>
          {selected === 'აირჩიეთ დომენის სახელი' && (
            <Box position="absolute" bottom="-10px" right="50%" transform="translateX(50%)">
              <ChevronDownIcon w={8} h={8} color="blue.500" />
            </Box>
          )}
        </MotionBox>
        <MotionBox
          as={VStack}
          initial="hidden"
          animate="visible"
          variants={iconVariants}
          transition={{ duration: 0.5, delay: 0.2 }}
          borderWidth="2px"
          borderColor="blue.500"
          borderRadius="lg"
          p={8}
          width={["auto", "auto", "auto", 80]}
          height="auto"
          textAlign="center"
          cursor="pointer"
          bg={selected === 'აირჩიეთ ჰოსტინგის გეგმა' ? 'blue.100' : 'white'}
          onClick={() => setSelected('აირჩიეთ ჰოსტინგის გეგმა')}
          position="relative"
        >
          <Box as="span" display="inline-block" p={4} borderRadius="full" bg="blue.200">
            <Icon as={MdLanguage} boxSize={12} color="blue.500" />
          </Box>
          <Text mt={4} fontWeight="bold" color="blue.700">
            აირჩიეთ ჰოსტინგის გეგმა
          </Text>
          {selected === 'აირჩიეთ ჰოსტინგის გეგმა' && (
            <Box position="absolute" bottom="-10px" right="50%" transform="translateX(50%)">
              <ChevronDownIcon w={8} h={8} color="blue.500" />
            </Box>
          )}
        </MotionBox>
        <MotionBox
          as={VStack}
          initial="hidden"
          animate="visible"
          variants={iconVariants}
          transition={{ duration: 0.5, delay: 0.4 }}
          borderWidth="2px"
          borderColor="blue.500"
          borderRadius="lg"
          p={8}
          width={["auto", "auto", "auto", 80]}
          height="auto"
          textAlign="center"
          cursor="pointer"
          bg={selected === 'შექმენით ვებგვერდი' ? 'blue.100' : 'white'}
          onClick={() => setSelected('შექმენით ვებგვერდი')}
          position="relative"
        >
          <Box as="span" display="inline-block" p={4} borderRadius="full" bg="blue.200">
            <Icon as={MdSettings} boxSize={12} color="blue.500" />
          </Box>
          <Text mt={4} fontWeight="bold" color="blue.700">
            შექმენით ვებგვერდი
          </Text>
          {selected === 'შექმენით ვებგვერდი' && (
            <Box position="absolute" bottom="-10px" right="50%" transform="translateX(50%)">
              <ChevronDownIcon w={8} h={8} color="blue.500" />
            </Box>
          )}
        </MotionBox>
      </HStack>
      <Box mt={10} w="full" maxW="2xl">
        {selected === 'აირჩიეთ დომენის სახელი' && (
          <DomainSearch
            domain={domain}
            setDomain={setDomain}
            searchDomain={searchDomain}
            loading={loading}
            result={result}
            renderDomainResult={renderDomainResult}
            suggestions={suggestions}
          />
        )}
        {selected === 'აირჩიეთ ჰოსტინგის გეგმა' && <HostingPlans />}
        {selected === 'შექმენით ვებგვერდი' && <WebsiteCreationInfo />}
      </Box>
    </Flex>
  );
};

export default BannerSection;
