import { Box, Flex, Text, Stack, Avatar, Icon, useColorModeValue } from '@chakra-ui/react';
import { FaStar } from 'react-icons/fa';

// Mock data
const reviews = [
  {
    name: 'Ilia',
    date: '',
    rating: 5,
    review: 'I have been using CleanNet Hosting for a high-traffic website for several months, and the service has been nothing short of exemplary. The uptime has been a solid 100%, which is crucial for maintaining the seamless operation of my busy site. Furthermore, the customer support is always responsive and helpful. For any business in need of reliable, high-performance hosting, I strongly recommend CleanNet',
  },
  {
    name: 'ნათია',
    date: '',
    rating: 5,
    review: 'ყველაზე სტაბილური ჰოსტინგია რაც გამომიყენებია, ჩვენი კომპანიის გაგზავნილი მეილები ყოველთვის სპამებში ვარდებოდა, როდესაც ვთანამშრომლობდით სხვა კომპანიასთან. გულწრფელი რომ ვიყო დავისვენე, როდესაც ქლინ ნეტთან დავიწყე თანამშრომლება    ',
  },
  {
    name: 'სალომე',
    date: '',
    rating: 5,
    review: 'საუკეთესო მომსახურეობა, საუკეთესო გუნდი და საუკეთესო ხარისხი უსწრაფესად. 2018 წლიდან მაოცებს თქვენი შესაძლებლობები. დიდი მადლობა თქვენ ასეთ კომფორტს რომ გვიქმნით!    ',
  },
  {
    name: 'თემო',
    date: '',
    rating: 5,
    review: 'ბევრად სწრაფად მუშაობს Wordpress საიტები ვიდრე სხვაგან, საფორთიც მშვენიერი რასაც ვუყურებ. მწარე გამოცდილება მივიღე სხვა ჰოსტინგებიდან.    ',
  },
  // add more reviews as needed
];

const ReviewCard = ({ name, date, rating, review }) => {
  return (
    <Box
      maxW={'320px'}
      w={'full'}
      bg={useColorModeValue('white', 'gray.900')}
      boxShadow={'2xl'}
      rounded={'md'}
      p={6}
      overflow={'hidden'}
    >
      <Stack>
        <Text
          color={'green.500'}
          textTransform={'uppercase'}
          fontWeight={800}
          fontSize={'sm'}
          letterSpacing={1.1}
        >
          {name} - {date}
        </Text>
        <Flex align="center" mb="4">
          {Array(5)
            .fill('')
            .map((_, i) => (
              <Icon as={FaStar} color={i < rating ? 'yellow.300' : 'gray.300'} />
            ))}
        </Flex>
        <Text color={'gray.500'}>{review}</Text>
      </Stack>
    </Box>
  );
};

const ReviewsSection = () => {
  return (
    <Flex justify="center" align="center" direction="column">
      <Text fontSize="2xl" mb={5}>
        მომხმარებლის შეფასებები Trustpilot -დან
      </Text>
      <Flex wrap="wrap" justify="center">
        {reviews.map((review, index) => (
          <Box key={index} mx={2} my={2}>
            <ReviewCard {...review} />
          </Box>
        ))}
      </Flex>
    </Flex>
  );
};

export default ReviewsSection;
