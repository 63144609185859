import React from "react";
import LoginPage from "./LoginPage";
import SeeAll from "./SeeAll"
import WebHosting from "./WebHosting"

import { ChakraProvider, CSSReset, Box } from '@chakra-ui/react';


function App() {
  return (
    <>
    <ChakraProvider>
    <LoginPage />
      <WebHosting />
   </ChakraProvider>
      
    </>
  );
}

export default App;
