import React, { useState } from 'react';
import {
  Box,
  Button,
  Input,
  InputGroup,
  InputRightElement,
  VStack,
  HStack,
  Center,
  Text,
  useColorModeValue,
  IconButton,
  Heading,
  Spinner,
} from '@chakra-ui/react';
import { SearchIcon } from '@chakra-ui/icons';

const DomainSearch = () => {
  const [domain, setDomain] = useState('');
  const [loading, setLoading] = useState(false);
  const [result, setResult] = useState(null);
  const [suggestions, setSuggestions] = useState([]);

  const checkDomain = async (domain) => {
    const response = await fetch(`https://cleannet.ge/checkdomain.php?domain=${domain}`);
    const data = await response.json();
    return data;
  };

  const searchDomain = async () => {
    setLoading(true);
    setResult(null);
    setSuggestions([]);
    const mainDomain = domain.includes('.') ? domain : `${domain}.ge`;
    const mainDomainData = await checkDomain(mainDomain);
    setResult({ domain: mainDomain, data: mainDomainData });

    const suggestedDomains = ['com.ge', 'org.ge', 'net.ge', 'edu.ge'];
    const suggestionResults = await Promise.all(
      suggestedDomains.map((ext) => checkDomain(`${domain.split('.')[0]}.${ext}`))
    );
    setSuggestions(
      suggestedDomains.map((ext, index) => ({
        domain: `${domain.split('.')[0]}.${ext}`,
        data: suggestionResults[index],
      }))
    );
    setLoading(false);
  };

  const getDomainPrice = (domain) => {
    const domainParts = domain.split('.');
    const zone = domainParts[domainParts.length - 1];
    if (zone === 'ge' && domainParts[0].length === 2 && !['com', 'net'].includes(domainParts[1])) {
      return 900;
    }
    const prices = {
      'com.ge': 12,
      'org.ge': 12,
      'net.ge': 12,
      'edu.ge': 12,
      com: 50,
      ge: 30,
      org: 52,
      net: 50,
      info: 40,
    };
    return prices[`${domainParts[1]}.${zone}`] || 30;
  };

  const renderDomainResult = (domainData, domain) => {
    if (!domainData) return null;
    const { result, status } = domainData;
    const price = getDomainPrice(domain);
    if (result === 'success' && status === 'available') {
      return (
        <Box className="result available" my={4} p={4} boxShadow="md" borderRadius="md" bg="green.100">
          <Text>{domain} დომენი თავისუფალია რეგისტრაციისთვის 🎉</Text>
          <a href={`https://cleannet.ge/cart.php?a=add&domain=register&query=${domain}`}>
            <Button colorScheme="teal" mt={2}>შეძენა ₾{price}</Button>
          </a>
        </Box>
      );
    }
    return (
      <Box className="result unavailable" my={4} p={4} boxShadow="md" borderRadius="md" bg="red.100">
        <Text>{domain} სამწუხაროდ დომენი უკვე დაკავებულია 😞</Text>
        {domain.includes('.ge') && (
          <a href={`https://cleannet.ge/cart.php?a=add&domain=transfer&query=${domain}`}>
            <Button colorScheme="orange" mt={2}>დაატრანსფერე ჩვენთან 😎</Button>
          </a>
        )}
      </Box>
    );
  };

  return (
    <Box py={10} px={5}>
      <Center>
        <VStack spacing={6} w="full" maxW="4xl">
          <Heading color="teal.500">Find Your Perfect Domain Name</Heading>
          <InputGroup size="lg" w="full" maxW="xl">
            <Input
              type="text"
              placeholder="Find your perfect domain name..."
              value={domain}
              onChange={(e) => setDomain(e.target.value)}
              bg="white"
              borderRadius="full"
              boxShadow="md"
              color="black"
            />
            <InputRightElement>
              <IconButton
                icon={<SearchIcon />}
                colorScheme="blue"
                borderRadius="full"
                onClick={searchDomain}
              />
            </InputRightElement>
          </InputGroup>
          {loading && <Spinner size="xl" color="teal.500" />}
          {!loading && result && (
            <Box id="resultContainer" w="full" maxW="xl" p={4} borderRadius="md" boxShadow="md" bg="white" color="black">
              {renderDomainResult(result.data, result.domain)}
              <VStack spacing={4}>
                {suggestions.map((suggestion) => renderDomainResult(suggestion.data, suggestion.domain))}
              </VStack>
            </Box>
          )}
          <HStack spacing={8} pt={4} wrap="wrap" justify="center">
            {[
              { ext: '.co', price: '$6.99' },
              { ext: '.io', price: '$39.99' },
              { ext: '.online', price: '$1.99' },
              { ext: '.club', price: '$2.99' },
              { ext: '.xyz', price: '$0.99' },
              { ext: '.com', price: '$8.99' },
            ].map((domain) => (
              <VStack key={domain.ext} spacing={2} align="center">
                <Text fontSize="3xl" fontWeight="bold" color="teal.500">
                  {domain.ext}
                </Text>
                <Text fontSize="md" color="gray.500">
                  {domain.price}
                </Text>
              </VStack>
            ))}
          </HStack>
        </VStack>
      </Center>
    </Box>
  );
};

export default DomainSearch;
