import React from 'react';

const ContactBox = () => {
  return (
    <>
      <section className='contact-promo ptb-120'>
        <div className='container'>
          <div className='row justify-content-center'>
            <div className='col-lg-4 col-md-6 mt-4 mt-lg-0'>
              <div className='contact-us-promo p-5 bg-white rounded-custom custom-shadow text-center d-flex flex-column h-100'>
                <span className='fad fa-comment-alt-lines fa-3x text-primary'></span>
                <div className='fa-solid fa-comment-info mb-4'>
                  <h5>Chat with us</h5>
                  <p>
                    We've got live Social Experts waiting to help you{' '}
                    <strong>monday to friday</strong> from
                    <strong>9am to 5pm EST.</strong>
                  </p>
                </div>
                <a
                  href='mailto:hellothemetags@gmail.com'
                  className='btn btn-link mt-auto'
                >
                  Chat with us
                </a>
              </div>
            </div>
            <div className='col-lg-4 col-md-6 mt-4 mt-lg-0'>
              <div className='contact-us-promo p-5 bg-white rounded-custom custom-shadow text-center d-flex flex-column h-100'>
                <span className='fa-solid fa-envelope fa-3x text-primary'></span>
                <div className='contact-promo-info mb-4'>
                  <h5>გამოგვიგზავნეთ შეტყობინება</h5>
                  <p>
                    გამოგვიგზვანეთ თქვენი შეტყობინება{' '}
                    <strong>support@cleannet.ge </strong>
                    ჩვენ მალე გიპასუხებთ 24/7
                  </p>
                </div>
                <a
                  href='mailto:hellothemetags@gmail.com'
                  className='btn btn-primary mt-auto'
                >
                  ელ-ფოსტა
                </a>
              </div>
            </div>
            <div className='col-lg-4 col-md-6 mt-4 mt-lg-0'>
              <div className='contact-us-promo p-5 bg-white rounded-custom custom-shadow text-center d-flex flex-column h-100'>
                <span className='fa-solid fa-phone fa-3x text-primary'></span>
                <div className='contact-promo-info mb-4'>
                  <h5>დაგვიკავშირდით</h5>
                  <p>
                    დაუკავშირდით ჩვენს სატელეფონო ცენტრს{' '}
                    <strong>ორშაბათი-პარასკევი</strong> ჩათვლით
                    <strong>9 -დან 18:00 საათამდე.</strong>
                  </p>
                </div>
                <a href='tel:00-976-561-008' className='btn btn-link mt-auto'>
                  +995 322 11 00 12
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ContactBox;
