import React from 'react';
import { Box, Button, Text, VStack, useColorModeValue } from '@chakra-ui/react';
import { Link } from 'react-router-dom';

const SectionHeader = () => {
  const start = useColorModeValue("blue.200", "blue.500");
  const end = useColorModeValue("blue", "blue.500");

  return (
    <VStack spacing={5} textAlign="center" py={5}>
      <Box 
        w={1230} 
        p={6} 
        boxShadow="xl" 
        borderWidth={1} 
        rounded="md" 
        bgGradient={`linear(to-l, ${start}, ${end})`}
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        <Text fontSize="xl" fontWeight="bold" color="white">
          მოთხოვნადი სერვისები
        </Text>
        <Button 
          as={Link} 
          to="/services" 
          colorScheme="blackAlpha" 
          size="lg" 
          rounded="md"
          _hover={{ boxShadow: "lg" }}
        >
            <Text fontSize="xl" fontWeight="bold" color="white">
          ყველა სერვისის ნახვა
        </Text>
        </Button>
      </Box>
    </VStack>
  );
};

export default SectionHeader;
