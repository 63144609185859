import { Box, Flex, Link, Text } from "@chakra-ui/react";
import { FaPhone } from "react-icons/fa";
import { FiLogIn } from "react-icons/fi";

const BlueSectionLine = () => {
  return (
    <Box
      bgGradient="linear(to-r, #0e1cb3, #fc6f03, #db0d36)"
      py={2}
      textAlign="center"
    >
      <Flex justify="space-between" align="center" px={4}>
        <Flex align="center" ml={8}>
          <FaPhone color="#FFFFFF" />
          <Link
            ml={2}
            color="#FFFFFF"
            href="tel:+1234567890"
            textDecoration="none"
          >
            <Text fontWeight="bold"> +995 322 11 00 12</Text>
          </Link>
        </Flex>
        <Flex align="center" mr={8}>
          <Link
            mr={4}
            color="#FFFFFF"
            href="/login"
            textDecoration="none"
            display="flex"
            alignItems="center"
            _hover={{ textDecoration: "underline" }}
          >
            <FiLogIn color="#FFFFFF" />
            <Text ml={2} fontWeight="bold">
              ავტორიზაცია
            </Text>
          </Link>
          <Link
            color="#FFFFFF"
            href="/change-language"
            textDecoration="none"
            _hover={{ opacity: "0.8" }}
          >
            <img
              src="https://upload.wikimedia.org/wikipedia/commons/thumb/8/88/United-states_flag_icon_round.svg/1024px-United-states_flag_icon_round.svg.png"
              alt="English"
              width={20}
              height={20}
            />
          </Link>
        </Flex>
      </Flex>
    </Box>
  );
};

export default BlueSectionLine;
